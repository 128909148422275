import {  
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  FormErrorMessage,
  Container,
  VStack,
  Heading,
  Input,
  FormControl,
  FormLabel,
  Link as ChakraLink,
  Checkbox,
  Select,
  Stack,
  useToast
} from "@chakra-ui/react";
import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {buildApiUrl} from "../libs/environment";
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {getDjangoCookies} from "../utils";

interface PlayerProfile {
  user: number,
  active : boolean,
  privacy: boolean, 
  first_name: string, 
  last_name: string,
  date_of_birth: string,
  email: string,
  gender: string,
  language: 'KOR' | 'ENG'
}

interface Props {
  playerProfileProp?: PlayerProfile
}


const ProfilePage: React.FC<Props> = ({playerProfileProp}) => {

  const cookies = getDjangoCookies();

  const [playerProfile, setPlayerProfile] = useState<PlayerProfile>({
    user: playerProfileProp? playerProfileProp.user: 0,
    active: playerProfileProp? playerProfileProp.active: false,
    privacy: playerProfileProp? playerProfileProp.privacy: false,
    first_name: playerProfileProp? playerProfileProp.first_name: '',
    last_name: playerProfileProp? playerProfileProp.last_name: '',
    date_of_birth: playerProfileProp? playerProfileProp.date_of_birth: '',
    email: playerProfileProp? playerProfileProp.email: '',
    gender: playerProfileProp? playerProfileProp.gender: 'U',
    language: playerProfileProp? playerProfileProp.language: 'ENG'
  });

  const toast = useToast();


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setPlayerProfile({ ...playerProfile, [name]: inputValue });
  };

  function handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const { name, value } = e.target;
    setPlayerProfile({
      ...playerProfile,
      [name]: value
    });
  }

  function handleDatepicker(date: Date) {
    const formattedDate = date.toISOString().split('T')[0];
    setPlayerProfile({ ...playerProfile, date_of_birth: formattedDate });
  }
  

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // Handle form submission here
    event.preventDefault();
  
// Set the default CSRF token in Axios headers
    let csrftoken = '';
    if (cookies.csrftoken) {
      csrftoken = cookies.csrftoken;
    } 
axios.defaults.headers.common['X-CSRFToken'] = csrftoken;

  // Make the POST request
  axios.patch(buildApiUrl('/api/update_profile/'), playerProfile)
    .then(response => {
      // Handle successful response
      if (response.status === 200) {
        setTimeout(function() {
          window.location.replace(buildApiUrl('/'));
        }, 5000);
            // Assume it was successful
          toast({
            title: 'Profile Updated',
            description: 'Profile updated successfully, will redirect to the front page soon',
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top'
          });
      }

    })
    .catch(error => {
      // Handle error
      console.log(error);
      toast({
        title: 'An error occurred.',
        description: 'Unable to update user profile',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    });

  };

  const isFirstNameError = playerProfile.first_name === '';
  const isLastNameError = playerProfile.last_name === '';

  return (
    <>
      <Container mt={4}>
        <Heading>
          Update Profile
        </Heading>
        <form onSubmit={handleSubmit}>
        <VStack alignItems={'flex-start'} maxW={'sm'} spacing={4} mt={6}>
          <FormControl isInvalid={isFirstNameError}>
            <FormLabel>First Name</FormLabel>
            <Input type='text' id='first_name' name="first_name" value={playerProfile.first_name} onChange={handleInputChange} />
            {isFirstNameError && <FormErrorMessage>First Name is required.</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={isLastNameError}>
            <FormLabel>Last Name</FormLabel>
            <Input type='text' id='last_name' name="last_name" value={playerProfile.last_name} onChange={handleInputChange }/>
            {isLastNameError && <FormErrorMessage>Last Name is required.</FormErrorMessage>}
          </FormControl>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <Select placeholder='Select option' value={playerProfile.gender} onChange={handleSelectChange} name='gender'>
                <option value='M'>Male</option>
                <option value='F'>Female</option>
                <option value='U'>Unknown</option>
            </Select>
          </FormControl>
          <FormControl>
          <FormLabel>Date of birth</FormLabel>
          <DatePicker
            selected={new Date(playerProfile.date_of_birth)}
            onChange={(date: Date) => handleDatepicker(date)}
          />
          </FormControl>
          <FormControl>
            <FormLabel>Language</FormLabel>
            <Select placeholder='Select option' value={playerProfile.language} onChange={handleSelectChange} name='language' >
                <option value='ENG'>English</option>
                <option value='KOR'>Korean</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Email address</FormLabel>
            <Input type='email' id='email' name="email" value={playerProfile.email} onChange={handleInputChange} />
          </FormControl>
          <Checkbox name="privacy" id="privacy" isChecked={playerProfile.privacy} onChange={handleInputChange}>Hide my ranking</Checkbox>
          <Button type='submit' >
            Save
          </Button>
        </VStack>
        </form>
      </Container>
    </>
  )
}

 export default ProfilePage
