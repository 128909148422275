import React, { FC } from "react";
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Announcement } from "../types";

type BoardTableProps = {
  announcements: Announcement[];
};

const BoardTable: FC<BoardTableProps> = ({ announcements }) => {
  // Sort announcements by date in descending order
  const sortedAnnouncements = [...announcements].sort((a, b) => b.date.getTime() - a.date.getTime());

  return (
    <TableContainer maxW={"container.lg"}>
      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Title</Th>
            <Th>Creator</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedAnnouncements.map((announcement) => (
            <React.Fragment key={announcement.id}>
              <Tr>
                <Td>{announcement.date.toLocaleString()}</Td>
                <Td>{announcement.title}</Td>
                <Td>{announcement.creator_name}</Td>
              </Tr>
              <Tr>
                <Td colSpan={3}>
                  <div style={{ background: "lightgray", padding: "8px", whiteSpace: "pre-line" }}>
                    {announcement.message}
                  </div>
                </Td>
              </Tr>
            </React.Fragment>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default BoardTable;
