import { FC, useEffect, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import { Box, Flex, Heading, Text, Alert, useToast, Stack } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { fetcher } from "../libs/backend";
import { getDjangoCookies } from "../utils";
import { useGlobal } from "../GlobalContext";
import ClubNav from "../components/ClubNav";
import MatchApprovalCard from "../components/MatchApprovalCard";
import RequestCard from "../components/RequestCard";
import { JoinRequest, Match } from "../interfaces";
import axios from 'axios';


/**
 * Manage join requests
 */
const ManageClub = () => {
  const cookies = getDjangoCookies();
  const { state: { current_group_id } } = useGlobal();
  const { data: joinRequests, error: joinRequestsError, isLoading: joinRequestsLoading } = useSWR<JoinRequest[]>(`/api/group/${current_group_id}/join_requests/`, fetcher);
  const { data: pendingMatches, error: matchesError, isLoading: isMatchesLoading } = useSWR<Match[]>(`/api/group/${current_group_id}/pending_matches/`, fetcher);

  const toast = useToast();
  const { mutate } = useSWRConfig();

  const approveRequest = (requestId: number) => {
    let csrftoken = '';
    if (cookies.csrftoken) {
      csrftoken = cookies.csrftoken;
    }
    axios.defaults.headers.common['X-CSRFToken'] = csrftoken;

    axios.patch(`/api/approve_join_request/`, { request_id: requestId })
      .then((response: any) => {
        toast({
          title: 'Request approved.',
          description: "Request approval done successfully.",
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top'
        });

        mutate(`/api/group/${current_group_id}/join_requests/`);
      });
  };

  const approveMatch = (matchId: number) => {
    let csrftoken = '';
    if (cookies.csrftoken) {
      csrftoken = cookies.csrftoken;
    }
    axios.defaults.headers.common['X-CSRFToken'] = csrftoken;

    axios.patch(`/api/approve_match_result/${matchId}/`)
      .then((response: any) => {
        toast({
          title: 'Match approved.',
          description: "Match approval done successfully.",
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top'
        });

        // Update the pendingMatches list by calling mutate
        mutate(`/api/group/${current_group_id}/pending_matches/`, (data: Match[] | undefined) => {
          // Filter out the approved match from the data
          const updatedMatches = data?.filter(match => match.match_id !== matchId) || [];
          return updatedMatches;
        });
      })
      .catch(error => {
        const errorMessage = error.response?.data?.message || "An error occurred.";
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top"
        });
      });
  };

  const rejectMatch = (matchId: number) => {
    let csrftoken = '';
    if (cookies.csrftoken) {
      csrftoken = cookies.csrftoken;
    }
    axios.defaults.headers.common['X-CSRFToken'] = csrftoken;

    axios.patch(`/api/reject_match_result/${matchId}/`)
      .then((response: any) => {
        toast({
          title: 'Match rejected.',
          description: "Match rejected successfully.",
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top'
        });

        // Update the pendingMatches list by calling mutate
        mutate(`/api/group/${current_group_id}/pending_matches/`, (data: Match[] | undefined) => {
          // Filter out the approved match from the data
          const updatedMatches = data?.filter(match => match.match_id !== matchId) || [];
          return updatedMatches;
        });
      })
      .catch(error => {
        const errorMessage = error.response?.data?.message || "An error occurred.";
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top"
        });
      });
  };

  return (
    <Flex>
      <ClubNav />

      <Flex direction="column" flex={1} p={2}>
        {joinRequestsLoading && <Text>Loading ...</Text>}
        {!joinRequestsLoading && joinRequests && joinRequests.length > 0 && (
          <>
            <Heading as="h2" size="lg" mb={4}>
              Join Requests
            </Heading>
            {joinRequests.map((request: JoinRequest) => (
              <RequestCard
                key={request.request_id}
                request={request}
                onApprove={approveRequest}
              />
            ))}
          </>
        )}
        {!joinRequestsLoading && (!joinRequests || joinRequests.length < 1) && (
          <Alert status="info">
            <InfoIcon />
            There are no join requests.
          </Alert>
        )}

        {isMatchesLoading && <Text>Loading ...</Text>}
        {!isMatchesLoading && pendingMatches && pendingMatches.length > 0 && (
          <>
            <Heading as="h2" size="lg" mt={6} mb={4}>
              Pending Matches
            </Heading>
            {pendingMatches.map((match: Match) => (
              <MatchApprovalCard
                key={match.match_id}
                match={match}
                onApprove={approveMatch}
                onReject={rejectMatch}
              />
            ))}
          </>
        )}
        {!isMatchesLoading && (!pendingMatches || pendingMatches.length < 1) && (
          <Alert status="info">
            <InfoIcon />
            There are no pending matches.
          </Alert>
        )}
      </Flex>
    </Flex>
  );

};

export default ManageClub;