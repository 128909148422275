import React from "react";
import { Box, Button, Stack } from "@chakra-ui/react";
import { Match } from "../interfaces";

interface MatchCardProps {
    match: Match;
    onApprove: (matchId: number) => void;
    onReject: (matchId: number) => void; // Define the onReject callback
}

const MatchApprovalCard: React.FC<MatchCardProps> = ({ match, onApprove, onReject }) => {
    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            my={4}
            key={match.match_id}
            bg="pink.100" // Set the background color to pastel pink
            color="black" // Set the font color for contrast
        >      <Stack spacing={4} p={4}>
                <Box fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase">
                    Event: {match.event_name}
                </Box>
                <Box>
                    Match Date: {match.date}
                </Box>
                <Stack spacing={2}>
                    <Box>
                        Team 1:
                    </Box>
                    <Box ml={4}>Player 1: {match.team_1_player_1_name}</Box>
                    <Box ml={4}>Player 2: {match.team_1_player_2_name || 'N/A'}</Box>
                </Stack>
                <Stack spacing={2}>
                    <Box>
                        Team 2:
                    </Box>
                    <Box ml={4}>Player 1: {match.team_2_player_1_name}</Box>
                    <Box ml={4}>Player 2: {match.team_2_player_2_name || 'N/A'}</Box>
                </Stack>
                <Stack spacing={2}>
                    <Box>
                        Score:
                    </Box>
                    <Box ml={4}>Team 1: {match.score_team_1}</Box>
                    <Box ml={4}>Team 2: {match.score_team_2}</Box>
                </Stack>
                <Button mt={4} onClick={() => onApprove(match.match_id)}>
                    Approve
                </Button>
                <Button mt={2} onClick={() => onReject(match.match_id)}>
                    Reject
                </Button>
            </Stack>
        </Box>
    );
};

export default MatchApprovalCard;
