import {FC, useState, useEffect} from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Container,
  Image,
  HStack,
  Link as ChakraLink,
  VStack,
  Text,
  useToast,
  Box,
  Divider,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Modal,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  Input, FormLabel
} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {ArrowForwardIcon} from "@chakra-ui/icons";
import {buildStaticUrl, buildApiUrl} from "../libs/environment";
import axios from 'axios';
import Cookies from 'js-cookie';

type ClubInfo = {
  group_name: string,
  description: string
  group_id: string,
  //joinRequestStatus?: 'pending' | 'joined'
}




// TODO: Fetch this from backend
/* const placeholderClubList: ClubInfo[] = [
  {
    group_name: 'Aoulim',
    description: 'some description about the club',
    clubId: '123',
    //joinRequestStatus: 'joined'
  },
  {
    group_name: 'Loveall',
    description: 'some description about the club',
    clubId: '234',
    //joinRequestStatus: 'pending'
  },
  {
    title: 'igoo',
    description: 'some description about the club',
    clubId: '345'
  },
  {
    title: 'other',
    description: 'some description about the club',
    clubId: '456'
  }
] */

type JoinClubModalProps = {
  isOpen: boolean
  onClose: () => void
  club?: ClubInfo
  csrfToken: string
}


const JoinClubModal: FC<JoinClubModalProps> = ({isOpen, onClose, club, csrfToken}) => {
  const toast = useToast()
  const [message, setMessage] = useState<string>('');
  const [rating, setRating] = useState<number>(0);

  if (!club) {
    return null
  }

  const joinClubRequest = () => {

    // TODO: Make backend api call to join club
    console.info(`Making request to join club`, {club, message, rating})

    axios.defaults.headers.common['X-CSRFToken'] = csrfToken;

    
  // Make the POST request
  axios.post(buildApiUrl('/api/join_request/'), {group_id: club.group_id, message_to_admin: message, requester_rating: rating})
    .then(response => {
      // Handle successful response
      if (response.status === 201) {
        setTimeout(function() {
          window.location.replace(buildApiUrl('/'));
        }, 5000);
          // Assume it was successful
          toast({
            title: 'Join request made.',
            description: "Your request will be reviewed by an admin. You'll be notified via email.",
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top'
          });
      }

    })
    .catch(error => {
      // Handle error
      console.log(error);
      toast({
        title: 'An error occurred.',
        description: 'Unable to update user profile',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    });
  

    onClose()
  }


  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader>Join: {club.group_name}</ModalHeader>
        <ModalCloseButton/>
        <ModalBody>
          <FormControl>
            <FormLabel>Message</FormLabel>
            <Input type='text' value={message} onChange={(e) => setMessage(e.target.value)}/>
          </FormControl>
          <FormControl>
            <FormLabel>My rating</FormLabel>
            <Input type='number' value={rating} onChange={(e) => setRating(parseInt(e.target.value))}/>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={(_e) => joinClubRequest()}>
            Make request
          </Button>
          <Button variant='ghost' onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const JoinClub = () => {

  const {isOpen, onOpen, onClose} = useDisclosure()
  const [joiningClub, setJoiningClub] = useState<ClubInfo | undefined>(undefined)
  const [clubList, setClubList] = useState<any[]>([]);
  const [csrfToken, setCSRFToken] = useState<string>('');
  const [isLoggedIn, setIsLoggedIn] = useState<Boolean>(true);

  useEffect(() => {
    const loadClubList = async () => {
      try {
        const response = await axios(buildApiUrl('/api/groups/'));
        setClubList(response.data);
      }  catch (error) {
        setIsLoggedIn(false);
        setTimeout(function() {
          window.location.replace(buildApiUrl('/accounts/kakao/login/?process=login'));
        }, 5000);
      }

    };
    setCSRFToken(Cookies.get('csrftoken')!);
    loadClubList();
  },[]);

  const closeAndClear = () => {
    onClose()
    setJoiningClub(undefined)
  }


  return (
    <>
      <Container mt={10}>
      {!isLoggedIn && <Alert status='error'>
          <AlertIcon />
            <AlertTitle>You are not logged in!</AlertTitle>
            <AlertDescription>Will redirect to login page soon. If doesn't work, please log in first by clicking <ChakraLink href={buildApiUrl('/accounts/kakao/login/?process=login')}>here</ChakraLink> </AlertDescription>
            </Alert>
      }
        <HStack>
          <Image src={buildStaticUrl('img/aoullim-logo-2024.jpg')} 
            borderRadius='full'
            boxSize='200px'
            alt='aoullim logo'
            
            />
          <VStack divider={<Divider/>} minW={'lg'}>
            {clubList.map((club) => {

                const joinButton = (!club.join_request_status && !club.has_joined) &&
                  <Button
                    onClick={(_e) => {
                      setJoiningClub(club)
                      onOpen()
                    }}
                    size={'sm'}
                  >
                    Request to join
                  </Button>

                return (
                  <HStack key={club.group_id} w={'100%'} justifyContent={'space-between'}>
                    <Box>
                      <Text fontSize={'lg'} fontWeight={'bold'}>{club.group_name}</Text>
                      <Text>{club.description}</Text>
                    </Box>
                    {joinButton}
                     {(club.join_request_status === 'pending') && <Text as={'i'}>Pending approval</Text>}
                    {(club.join_request_status === 'joined' || club.has_joined) &&
                      <Link to={`/dashboard?clubId=${club.group_id}`}>
                        <Button
                          colorScheme={'blue'}
                          size={'sm'}
                          rightIcon={<ArrowForwardIcon/>}
                        >
                          Open dashboard
                        </Button>
                      </Link>} 
                  </HStack>
                )
              }
            )}
          </VStack>
        </HStack>
      </Container>
      <JoinClubModal onClose={closeAndClear} isOpen={isOpen} club={joiningClub} csrfToken={csrfToken}/>
    </>
  )
}

export default JoinClub
