import { FC, useState, useEffect } from "react";
import { Center, Heading, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import MatchFilter from "./MatchFilter";
import { useGlobal } from "../GlobalContext";
import { fetcher } from "../libs/backend";
import useSWR from "swr";

interface Match {
  date: string;
  event_name: string;
  match_id: number;
  match_result: string;
  match_type: 'D' | 'S' | 'P';
  score_team_1: number;
  score_team_2: number;
  status: string;
  team_1_player_1_name?: string;
  team_1_player_2_name?: string;
  team_2_player_1_name?: string;
  team_2_player_2_name?: string;
}

interface DateRange {
  start_date: string;
  end_date: string;
}

interface SeasonInterFace {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  min_count: number;
  group: number;
}

type ChildProps = {
  updateParentState: (newValue: any) => void;
  currentSelectedSeasonObj: SeasonInterFace;
  seasonsData: SeasonInterFace;
};
const MatchTable: FC<ChildProps> = ( {updateParentState, currentSelectedSeasonObj, seasonsData} ) => {
  const [selectedEvent, setSelectedEvent] = useState<any>("");
  const [selectedMatch, setSelectedMatch] = useState<any>("D");
  const { dispatch, state: { current_group_id, current_season_id } } = useGlobal();

  // State date range
  const defaultStartDate = currentSelectedSeasonObj.start_date;
  const defaultEndDate = currentSelectedSeasonObj.end_date;
  const [dateRange, setDateRange] = useState<DateRange>({ start_date: defaultStartDate, end_date: defaultEndDate });

  const { data, error, isLoading } = useSWR(`/api/matches/${current_group_id}/${current_season_id}/${selectedMatch !== "" ? selectedMatch + "/" : ""}${selectedEvent !== "" ? selectedEvent + "/" : ""}?start_date=${dateRange.start_date}&end_date=${dateRange.end_date}`, fetcher);

  function handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
    if (e.target.value !== "") {
      setSelectedEvent(e.target.value);
    }
  }

  function handleSelectChangeMatch(e: React.ChangeEvent<HTMLSelectElement>) {
    if (e.target.value !== "") {
      setSelectedMatch(e.target.value);
    }
  }

  if (!isLoading) {
    updateParentState(data);
  }

  return (
    <>
      <Heading size="lg">My History</Heading>
      <MatchFilter currentSelectedSeasonId={currentSelectedSeasonObj.id} onSelectChange={handleSelectChange} onSelectChangeMatch={handleSelectChangeMatch} onChangeStartDate={e => setDateRange(prevState => ({ ...prevState, start_date: e.target.value }))} onChangeEndDate={e => setDateRange(prevState => ({ ...prevState, end_date: e.target.value }))} dateRange={dateRange} />
      {isLoading ? <Center><Heading>Loading....</Heading></Center> : (
        <>
          {!isLoading && data != undefined && data.length > 0 ? (
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Event</Th>
                    <Th>Match type</Th>
                    <Th>Score team 1</Th>
                    <Th>Score team 2</Th>
                    <Th>Team 1</Th>
                    <Th>Team 2</Th>
                    <Th>Match result</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((match: Match, index: number) => (
                    <Tr key={index}>
                      <Td>{match.date}</Td>
                      <Td>{match.event_name}</Td>
                      <Td>{match.match_type}</Td>
                      <Td>{match.score_team_1}</Td>
                      <Td>{match.score_team_2}</Td>
                      <Td>{match.team_1_player_1_name} {match.team_1_player_2_name ? `& ${match.team_1_player_2_name}` : ""}</Td>
                      <Td>{match.team_2_player_1_name} {match.team_2_player_2_name ? `& ${match.team_2_player_2_name}` : ""}</Td>
                      <Td>{match.match_result}</Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th>Date</Th>
                    <Th>Event</Th>
                    <Th>Match type</Th>
                    <Th>Score team 1</Th>
                    <Th>Score team 2</Th>
                    <Th>Team 1</Th>
                    <Th>Team 2</Th>
                    <Th>Match result</Th>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
          ) : (
            <Center><Heading as='h3' size='xl'>No history found</Heading></Center>
          )}
        </>
      )}
    </>
  );
}

export default MatchTable;
