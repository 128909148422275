import {NODE_ENV} from "../config";

/**
 * Build production url if in prod mode.
 *
 * @param url - must be without leading slash
 */
export function buildStaticUrl(url: string) {
  return `${NODE_ENV === 'production' ? '/static/' : ''}${url}`
}

/**
 * Build production url if in prod mode for api/server pages.
 * @param url
 */
export function buildApiUrl(url: string) {
  return `${NODE_ENV === 'production' ? '' : 'http://127.0.0.1:8000'}${url}`
}

