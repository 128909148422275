import { FC } from "react";
import { CircularProgress, CircularProgressLabel, Flex, Text } from "@chakra-ui/react";
interface Props {
  winningPercentage: any 
}
const WinRateGraph: FC<Props> = ({ winningPercentage }) => {

  return (
    <Flex direction="column" alignItems="center">
      <CircularProgress
        value={parseInt(winningPercentage)}
        size="120px" // Update size to a single value
        thickness={8}
        color="green.400"
      >
        <CircularProgressLabel>{winningPercentage}%</CircularProgressLabel>
      </CircularProgress>
    </Flex>
  );
};

export default WinRateGraph;
