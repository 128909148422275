import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link as ChakraLink,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure, MenuDivider, MenuList, MenuButton, MenuItem, Menu, MenuGroup,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {Link} from "react-router-dom";
import {useGlobal} from "../GlobalContext";
import React, {useCallback, useState, useEffect} from "react";
import {buildApiUrl} from "../libs/environment";
import axios from 'axios';


interface Props {
  first_name: string,
  last_name: string,
  language: any
}



const GlobalNav:React.FC<Props> = ({first_name, last_name, language}) => {
  const {isOpen, onToggle} = useDisclosure();
  const { dispatch } = useGlobal();
  const {
    state: { current_group_id },
  } = useGlobal();
  const [currentClub, setCurrentClub] = useState<any | undefined>({});
  useEffect(()=> {
    console.log(current_group_id);
    const fetchCurrentClub = async () => {
      try {
        const response = await axios(buildApiUrl(`/api/group/${current_group_id}/`));
        setCurrentClub(response.data);
      } catch (error) {
        setTimeout(function() {
          window.location.replace('/accounts/kakao/login/?process=login');
        }, 5000);

        
      }
    };

    fetchCurrentClub();
  }, [current_group_id]);


  return (
    <Box>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{base: 2}}
        px={{base: 4}}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{base: 1, md: 'auto'}}
          ml={{base: -2}}
          display={{base: 'flex', md: 'none'}}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3}/> : <HamburgerIcon w={5} h={5}/>
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{base: 1}} justify={{base: 'center', md: 'start'}}>
          <Text
            textAlign={useBreakpointValue({base: 'center', md: 'left'})}
            fontFamily={'heading'}
            color={useColorModeValue('gray.800', 'white')}
            fontWeight={'extrabold'}
            fontSize={'lg'}
          >
            <Link to={'/'}>{currentClub.group_name}</Link>
          </Text>
          <Flex display={{base: 'none', md: 'flex'}} ml={10}>
            <DesktopNav/>
          </Flex>
        </Flex>

        <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                as={Button}
                variant={'ghost'}
                cursor={'pointer'}
                minW={0}
                size={'sm'} rightIcon={<ChevronDownIcon/>}>
                <Text>Welcome {language === 'KOR'? last_name+first_name: first_name+' '+last_name }</Text>
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <Link to={'/profile'}>Profile</Link>
                </MenuItem>
                <MenuItem onClick={() => window.location.replace('/accounts/logout/')}>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav/>
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const {dispatch} = useGlobal()

  const [myClubList, setMyClubList] = useState<any | undefined>([]);
  const [loadingClubList, setLoadingClubList] = useState<boolean>(false);
  useEffect(()=> {

    const fetchClubList = async () => {
      try {
        const response = await axios(buildApiUrl('/api/groups/'));
        const joinedClub = response.data.filter((club:any) => club.is_active && club.has_joined);
        setMyClubList(joinedClub);
      } catch (error) {
        setTimeout(function() {
          window.location.replace('/accounts/kakao/login/?process=login');
        }, 5000);

        
      } finally {
        setLoadingClubList(true);
      }
    };

    fetchClubList();
  }, []);

  const selectClub = useCallback((group_id: number) => {
    console.log('select club', group_id)
    dispatch({type: "set_group", group_id});
    window.location.replace('/dashboard?clubId='+group_id);
  }, [dispatch])

  return (
    <Stack direction={'row'} spacing={4}>
      <Box>

      <Menu>
        <MenuButton as={Button} variant={'ghost'} size={'sm'} rightIcon={<ChevronDownIcon/>}>
          Select club
        </MenuButton>
        <MenuList>
          {/*TODO: Get list of my clubs from API*/}
          <MenuGroup title='My Clubs'>
            {
              myClubList.map((myClub:any)=> <MenuItem onClick={() => selectClub(myClub.group_id)}>{myClub.group_name}</MenuItem>)
            }
          </MenuGroup>
          <MenuDivider/>
          <MenuItem>
            <Link to={'/join-club'}>
              Other clubs ...
            </Link>
          </MenuItem>
        </MenuList>
      </Menu>
      </Box>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link to={navItem.href ?? '#'}>
                <ChakraLink
                  as={'span'}
                  p={2}
                  fontSize={'sm'}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: 'none',
                    color: linkHoverColor,
                  }}>
                  {navItem.label}
                </ChakraLink>
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({label, href, subLabel}: NavItem) => {
  return (
    <Link to={href ?? '#'}>
      <Flex
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{bg: useColorModeValue('pink.50', 'gray.900')}}>
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{color: 'pink.400'}}
              fontWeight={500}>
              {label}
            </Text>
            <Text fontSize={'sm'}>{subLabel}</Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{opacity: '100%', transform: 'translateX(0)'}}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon}/>
          </Flex>
        </Stack>
      </Flex>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{md: 'none'}}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({label, children, href}: NavItem) => {
  const {isOpen, onToggle} = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Link to={href ?? '#'}>
        <Flex
          py={2}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}>
          <Text
            fontWeight={600}
            color={useColorModeValue('gray.600', 'gray.200')}>
            {label}
          </Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={'all .25s ease-in-out'}
              transform={isOpen ? 'rotate(180deg)' : ''}
              w={6}
              h={6}
            />
          )}
        </Flex>
      </Link>

      <Collapse in={isOpen} animateOpacity style={{marginTop: '0!important'}}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link to={child.href ?? '#'}>
                <Flex key={child.label} py={2}>
                  {child.label}
                </Flex>
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  // {
  //   label: 'Dashboard',
  //   href: '/dashboard',
  // },
  // {
  //   label: 'Announcements',
  //   href: '/board',
  // },
  // {
  //   label: 'Profile',
  //   href: '/profile',
  // },
];

export default GlobalNav;