import {createContext, useContext, useReducer, ReactNode} from "react";

type Action =
  | { type: 'set_group', group_id: number }
  | { type: 'set_season', season_id: number };
type Dispatch = (action: Action) => void
type State = { current_group_id?: number; current_season_id?: number; }
type UserProviderProps = { children: ReactNode }

const GlobalStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)

function groupReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set_group': {
      return {...state, current_group_id: action.group_id }
    }
    case 'set_season': {
      return {...state, current_season_id: action.season_id }
    }
    default: {
      throw new Error(`Unhandled action type: ${state}`)
    }
  }
}




function GlobalProvider({children}: UserProviderProps) {
  const [state, dispatch] = useReducer(groupReducer, {})
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = {state, dispatch}
  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  )
}

function useGlobal() {
  const context = useContext(GlobalStateContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a GlobalProvider')
  }
  return context
}

export {GlobalProvider, useGlobal}
