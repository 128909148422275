import { FC } from "react";
import {Select} from "@chakra-ui/react";
import { fetcher } from "../libs/backend";
import useSWR from "swr";
import { useGlobal } from "../GlobalContext";

type SeasonFilterProps = {
  selectedSeason: number;
  onSelectChangeSeason: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};
const SeasonFilter: FC<SeasonFilterProps> = ({selectedSeason, onSelectChangeSeason}) => {
    const { state: { current_group_id, current_season_id } } = useGlobal();
    const { data: seasonsData, error: seasonsError } = useSWR<Seasons[]>(`api/group/${current_group_id}/seasons/`, fetcher);

    interface Seasons {
        id: number;
        name: string;
        start_date: string;
        end_date: string;
        min_count: number;
    }

    const handleSeasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      onSelectChangeSeason(e); // Pass the selected season ID to the parent
    };

    return (
        <Select value={selectedSeason} placeholder="Season" onChange={handleSeasonChange}>
          {Array.isArray(seasonsData) &&
            seasonsData.map((season: Seasons) => (
                <option key={season.id} value={season.id}>
                  {season.name}
                </option>
              ))}
        </Select>
      );

}

export default SeasonFilter;