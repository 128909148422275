import { useState, FC, useMemo, useEffect } from "react";
import useSWR, { useSWRConfig } from "swr";
import { Alert, AlertIcon, Stack, HStack, Text, Select, Box, Input } from "@chakra-ui/react";
import axios from "axios";
import ClubNav from "../components/ClubNav";
import PlayerTable from "../components/PlayerTable";
import { PlayerStat } from "../types";
import { fetcher } from "../libs/backend";
import { useGlobal } from "../GlobalContext";
import { getDjangoCookies } from "../utils";
import EventTypeFilter from "../components/EventTypeFilter";
import SeasonFilter from "../components/SeasonFilter";

interface Event {
  event_id: number;
  event_name: string;
}

interface DateRange {
  start_date: string;
  end_date: string;
}

/**
 * Show leaderboard. (sorted by ELO rating)
 */
const LeaderBoard: FC = () => {
  const cookies = getDjangoCookies()
  const { state: { current_group_id, current_season_id } } = useGlobal()
  const { mutate } = useSWRConfig();


  // State for selected event
  const [selectedEvent, setSelectedEvent] = useState<any>('All');  // Set 'None' as the default selected event
  const [selectedMatchType, setSelectedMatchType] = useState<any>('D'); // Set 'D' as the default selected match type
  const [selectedSeasonId, setSelectedSeasonId] = useState<number | undefined>(current_season_id);

  // Fetch ratings data from api
  const { data, error, isLoading } = useSWR(`/api/leaderboard/?season=${selectedSeasonId}&event=${selectedEvent}&match_type=${selectedMatchType}`, fetcher, { shouldRetryOnError: false, revalidateOnFocus: false })

  useEffect(() => {
    mutate(`/api/leaderboard/?season=${selectedSeasonId}&event=${selectedEvent}&match_type=${selectedMatchType}`);
  }, [selectedSeasonId, selectedEvent, selectedMatchType]);

  function handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedEvent(e.target.value);
  }

  function handleSelectChangeSeason(e: React.ChangeEvent<HTMLSelectElement>) {
    if (e.target.value !== "") {
      setSelectedSeasonId(parseInt(e.target.value));
      setSelectedEvent('All');  // Reset to default value
      setSelectedMatchType('D');  // Reset to default value
    }
  }
  
  // Parse ratings if backend returned data
  const playerStats: PlayerStat[] = useMemo<PlayerStat[]>(
    () => {
      // On error return empty list, or if returned data is in bad shape
      if (!cookies.csrftoken || error || !data || !Array.isArray(data)) {
        return []
      }

      // parse API response to fit PlayerStat
      return data.map((p: any, index) => {
        // TODO: actually parse info and get data
        return { rank: index + 1, name: p.player_name, eloRating: p.rating, event_data: p.event_data }
      })
    },
    [data, error]
  )

  if (!selectedSeasonId) {
    return null;
  } else {
    return (
      <>
        <Stack direction={['column', 'row']} spacing={5}>
          <ClubNav />
          <Stack direction='column' width="100%" paddingLeft={5}>
            <HStack>
              <Box flex={1} width={{ base: "100%", md: "25%" }}>
                <Text fontWeight={'semibold'}>Season</Text>
                <SeasonFilter selectedSeason={selectedSeasonId} onSelectChangeSeason={handleSelectChangeSeason} />
              </Box>
              <Box flex={1} width={{ base: "100%", md: "75%" }}>
                <Text fontWeight={'semibold'}>Event</Text>
                <EventTypeFilter currentSelectedSeasonId={selectedSeasonId} onSelectChange={handleSelectChange} hideAll={false} />
              </Box>
              <Box flex={1} width={{ base: "100%", md: "25%" }}>
                <Text fontWeight={'semibold'}>Match Type</Text>
                <Select placeholder="Select Match Type" onChange={(e) => setSelectedMatchType(e.target.value)}>
                  <option value="S">Singles</option>
                  <option value="D">Doubles</option>
                </Select>
              </Box>
            </HStack>

            {isLoading && <Text>Loading ...</Text>}
            {!isLoading && playerStats.length > 0 && (
              <Box flex={1} p={2}>
                <PlayerTable playerStats={playerStats} />
              </Box>
            )}
            {!isLoading && playerStats.length < 1 && (
              <Alert status='warning' alignSelf={'flex-start'}>
                <AlertIcon />
                Empty ratings data.
              </Alert>
            )}
          </Stack>
        </Stack>
      </>
    )
  }


}
export default LeaderBoard;