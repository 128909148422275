import React, { useState, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  Button, 
  Center,
  Container, 
  Image, 
  Link as ChakraLink, 
  useColorModeValue,
  VStack
} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {buildStaticUrl, buildApiUrl} from "../libs/environment";


const LandingPage: React.FC = () => {
  const bg = useColorModeValue('white', 'gray.700');

  return (
    <Container>
      <VStack>
          <Image src={buildStaticUrl('img/aoullim-logo-2024.jpg')} 
            borderRadius='full'
            boxSize='200px'
            alt='aoullim logo'
            />
          <Center rounded={'lg'} bg={bg} boxShadow={'lg'} p={8} >
              <ChakraLink href={buildApiUrl('/accounts/kakao/login/?process=login')}>
                <Image src={buildStaticUrl('img/kakao_login_medium_wide.png')} alt='kakaotalk login' />
              </ChakraLink>
          </Center>
      </VStack>
    </Container>
  )
}

export default LandingPage
