import { FC } from "react";
import { Select } from "@chakra-ui/react";
import { fetcher } from "../libs/backend";
import useSWR from "swr";
import { useGlobal } from "../GlobalContext";

type EventTypeFilterProps = {
  currentSelectedSeasonId?: number,
  onSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  hideAll: boolean
}

const EventTypeFilter: FC<EventTypeFilterProps> = ({ currentSelectedSeasonId = null, onSelectChange, hideAll }) => {
  const { state: { current_group_id, current_season_id } } = useGlobal();
  const { data: eventsData, error: eventsError } = useSWR<Event[]>(`api/group/${current_group_id}/events/?${currentSelectedSeasonId ? currentSelectedSeasonId : current_season_id}/`, fetcher);

  interface Event {
    event_id: number;
    event_name: string;
  }
  return (
    <Select placeholder="Event Type" onChange={onSelectChange}>
      <option value="All">All</option>
      {Array.isArray(eventsData) &&
        eventsData
          .filter((event: Event) => !hideAll || event.event_name !== "All")
          .map((event: Event) => (
            <option key={event.event_id} value={event.event_id}>
              {event.event_name}
            </option>
          ))}
    </Select>
  );

}

export default EventTypeFilter;