import axios from 'axios'

/**
 * Call python backend API via Axios, to be used with swr.
 *
 * Since DJango will set sessionid on cookie, all URL paths on same domain
 * will automatically have the cookie set.
 *
 * @param url
 */
const fetcher = (url: string) => {
  if (url.search('/undefined/') > -1) {
    return Promise.resolve({})
  } else {
    return axios.get(url).then(res => res.data)
  }
}

export {
  fetcher
}
