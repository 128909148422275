import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { 
  Button, 
  Heading, 
  Text, 
  VStack, 
  Drawer, 
  DrawerBody, 
  DrawerHeader, 
  DrawerOverlay, 
  DrawerContent, 
  IconButton,
  useBreakpointValue
} from "@chakra-ui/react";
import { HamburgerIcon } from '@chakra-ui/icons';

import RecordNewMatchButton from "./RecordNewMatchButton";
import { useGlobal } from "../GlobalContext";
import { fetcher } from "../libs/backend";
import useSWR from "swr";


/**
 * Club specific navigation menu.
 *
 * @constructor
 */
const ClubNav: FC = () => {

  const { state: { current_group_id } } = useGlobal()
  const { data: groupData, error: groupError, isLoading: groupLoading } = useSWR(`/api/group/${current_group_id}/`, fetcher)
  const { data: roleData, error: roleError, isLoading: roleLoading } = useSWR(`/api/role/${current_group_id}/`, fetcher)
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (roleData && roleData.membership_type) {
      console.log(`Role in the group: ${roleData.membership_type}`);
    }
  }, [roleData]);

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      {isMobile ? (
        <>
          <IconButton 
            aria-label="Open menu" 
            icon={<HamburgerIcon />} 
            variant="outline"
            onClick={() => setDrawerOpen(true)} 
          />
          <Drawer isOpen={isDrawerOpen} placement="left" onClose={() => setDrawerOpen(false)}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader borderBottomWidth="1px">
                {groupData?.group_name || "-"}
              </DrawerHeader>
              <DrawerBody>
                <VStack alignItems='flex-start' p={2}>
                  <RecordNewMatchButton />
                  {(roleData && (roleData.membership_type === 'A' || roleData.membership_type === 'O')) && (
                    <> 
                      <Link to='/requests'>
                        <Button variant='ghost'>
                          Pending Requests
                        </Button>
                      </Link>
                      <Link to='/attendance-tracker'>
                        <Button variant='ghost'>
                          Attendance Tracker
                        </Button>
                      </Link>
{/*                       <Link to='/manage-court'>
                        <Button variant='ghost'>
                         Manage Court
                        </Button>
                      </Link> */}
                    </>
                  )}
                  <Link to='/dashboard'>
                    <Button variant='ghost'>
                      Dashboard
                    </Button>
                  </Link>
                  <Link to='/announcements'>
                    <Button variant='ghost'>
                      Announcements
                    </Button>
                  </Link>
                  <Link to='/leaderboard'>
                    <Button variant='ghost'>
                      Leaderboard
                    </Button>
                  </Link>
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      ) : (
        <VStack alignItems='flex-start' p={2}>
          <RecordNewMatchButton />
          {(roleData && (roleData.membership_type === 'A' || roleData.membership_type === 'O')) && (
            <> 
              <Link to='/requests'>
                <Button variant='ghost'>
                  Pending Requests
                </Button>
              </Link>
              <Link to='/attendance-tracker'>
              <Button variant='ghost'>
                  Attendance Tracker
                </Button>
              </Link>
{/*               <Link to='/manage-court'>
              <Button variant='ghost'>
                  Manage Court
                </Button>
              </Link> */}
            </>
          )}
          <Link to='/dashboard'>
            <Button variant='ghost'>
              Dashboard
            </Button>
          </Link>
          <Link to='/announcements'>
            <Button variant='ghost'>
              Announcements
            </Button>
          </Link>
          <Link to='/leaderboard'>
            <Button variant='ghost'>
              Leaderboard
            </Button>
          </Link>
        </VStack>
      )}
    </>
  )
}

export default ClubNav
