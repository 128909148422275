import { FC } from "react";
import { Select } from "@chakra-ui/react";

type MatchTypeFilterProps = {
    fromDashBoard: boolean;
    onSelectChangeMatch: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const MatchTypeFilter: FC<MatchTypeFilterProps> = ({ fromDashBoard, onSelectChangeMatch }) => {
    return (
        <Select placeholder="Match Type" onChange={onSelectChangeMatch}>
            {fromDashBoard && <option value="All">All matches</option>}
            <option value="S">Single</option>
            <option value="D">Double</option>
            <option value="P">Practice</option>
        </Select>
    );
};

export default MatchTypeFilter;
