import { useState, FC, useMemo, useEffect } from "react";
import useSWR from 'swr'
import { Alert, AlertIcon, Stack, Text, Select, Box, Table, Thead, Tbody, Td, Tfoot, Th, Tr, Heading, Input } from "@chakra-ui/react";

import ClubNav from "../components/ClubNav";
import SeasonFilter from "../components/SeasonFilter";
import { PlayerStat } from "../types";
import { fetcher } from "../libs/backend";
import { useGlobal } from "../GlobalContext";
import { getDjangoCookies } from "../utils";

interface Event {
  event_id: number;
  event_name: string;
}

interface DateRange {
  start_date: string;
  end_date: string;
}

interface SeasonInterFace {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  min_count: number;
  group: number;
}

/**
 * Show leaderboard. (sorted by ELO rating)
 */
const AttendanceTracker: FC = () => {
  const cookies = getDjangoCookies()
  const { state: { current_group_id, current_season_id } } = useGlobal()

  // Fetch events data from api
  const { data: eventsData, error: eventsError } = useSWR<Event[]>(`/api/group/${current_group_id}/events/`, fetcher);

  // State for selected event
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(eventsData?.[0]);

  // State for selected match type; default to "D"
  const [selectedMatchType, setSelectedMatchType] = useState<string | undefined>('D');

  const [currentSeason, setCurrentSeason] = useState<SeasonInterFace | null>(null);

  const [selectedSeasonId, setSelectedSeasonId] = useState<number | undefined>(current_season_id);

  // State date range
  const defaultStartDate = new Date(new Date().getFullYear(), 0, 1).toISOString().split("T")[0];
  const defaultEndDate = new Date().toISOString().split("T")[0];
  const [dateRange, setDateRange] = useState<DateRange>({ start_date: defaultStartDate, end_date: defaultEndDate });

  // Fetch ratings data from api
  const { data, error, isLoading } = useSWR(`/api/leaderboard/${selectedEvent?.event_id}?${selectedMatchType}/`, fetcher, { shouldRetryOnError: false, revalidateOnFocus: false })

  // Fetch seasons data from api
  const { data: seasonsData, error: seasonsError } = useSWR(`/api/group/${current_group_id}/seasons/`, fetcher, { shouldRetryOnError: false, revalidateOnFocus: false })

  // Fetch participation count of all players
  const { data: groupAttendanceData, error: groupAttendanceError } = useSWR(`/api/group/${current_group_id}/attendance_list/season/${selectedSeasonId}`, fetcher, { shouldRetryOnError: false, revalidateOnFocus: false })

  useEffect(() => {

    const selectedSeason = seasonsData.find((item:SeasonInterFace) => item.id === current_season_id);
    setCurrentSeason(selectedSeason);

    const getCurrentSeason = async () => {
      const todayDate: string = new Date().toISOString().split('T')[0];

      // Find the element where today's date falls within the date range
      const foundElement = seasonsData.find((item:SeasonInterFace) => item.start_date <= todayDate && todayDate <= item.end_date && item.name !== 'All');

      setCurrentSeason(foundElement);

    };

    getCurrentSeason();
  },[seasonsData])

  // Parse ratings if backend returned data
  const playerStats: PlayerStat[] = useMemo<PlayerStat[]>(
    () => {
      // On error return empty list, or if returned data is in bad shape
      if (!cookies.sessionid || error || !data?.data || !Array.isArray(data.data)) {
        return []
      }

      // parse API response to fit PlayerStat
      return data.data.map((p: any) => {
        // TODO: actually parse info and get data
        return { rank: 0, name: '', gamesPlayed: 0, wins: 0, winPercentage: 0, eloRating: 0 }
      })
    },
    [data, error]
  )

  function handleSelectChangeSeason(e: React.ChangeEvent<HTMLSelectElement>) {
    if (e.target.value !== "") {
      setSelectedSeasonId(parseInt(e.target.value));
      const selectedSeason = seasonsData.find((item:SeasonInterFace) => item.id === parseInt(e.target.value));
      setCurrentSeason(selectedSeason);
    }
  }

  return (
    <>
      <Stack direction={['column', 'row']} spacing={5}>
        <ClubNav />
        <Stack direction='column' width="100%" paddingLeft={5}>
          {isLoading && <Text>Loading ...</Text>}
          {
            (!isLoading && groupAttendanceData && currentSeason) && (
              <Box flex={1} p={2}>
                <Heading>Attendance Tracker for {currentSeason.name} season </Heading>
                <Box flex={1} width={{ base: "100%", md: "25%" }}>
                  <Text fontWeight={'semibold'}>View other season</Text>
                  <SeasonFilter selectedSeason={currentSeason.id} onSelectChangeSeason={handleSelectChangeSeason}/>
                </Box>
                <Table variant="striped" size="sm">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th># times attended</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {groupAttendanceData.map((l:any, i:any) =>
                      <Tr key={i}>
                        <Td>{l.player_name}</Td>
                        <Td>{l.participation_count}</Td>
                      </Tr>
                    )}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>Name</Th>
                      <Th># times attended</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </Box>
            )
          }
        </Stack>
      </Stack>
    </>
  )
}
export default AttendanceTracker;