import React, { useState, useEffect } from 'react';
import {
  Route,
  Routes,
  BrowserRouter as Router
} from "react-router-dom";

import {GlobalProvider} from "./GlobalContext";
import LandingPage from './pages/Landing';
import JoinClub from "./pages/JoinClub";
import ProfilePage from "./pages/Profile";
import AnnouncementPage from "./pages/Announcements";
import LeaderBoard from "./pages/LeaderBoard"
import DashBoard from "./pages/DashBoard";
import {buildApiUrl} from "./libs/environment";
import GlobalNav from "./components/GlobalNav";
import ManageClub from "./pages/ManageClub";
import ManageCourt from "./pages/ManageCourt";
import AttendanceTracker from "./pages/AttendanceTracker";
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import useSWR from "swr";
import { getDjangoCookies } from "./utils";
import { fetcher } from "./libs/backend";

interface PlayerProfile {
  user: number,
  active : boolean,
  privacy: boolean, 
  first_name: string, 
  last_name: string,
  date_of_birth: string,
  email: string,
  gender: string,
  language: 'KOR' | 'ENG'
}

const App = () => {

  const { data: playerProfile, error: playerProfileError } = useSWR<PlayerProfile>(buildApiUrl('/api/player_info/'), fetcher);
  let isLoggedIn;
  if (playerProfile && !playerProfileError) {
    isLoggedIn = true;
  } else {
    isLoggedIn = false;
  }
    return (
    
        <GlobalProvider>
          <Router>
            {(isLoggedIn && playerProfile) && <GlobalNav first_name={playerProfile.first_name} last_name={playerProfile.last_name} language={playerProfile.language} />}
            <Routes>
              <Route path="/" element={(isLoggedIn && playerProfile)? <DashBoard first_name={playerProfile.first_name} last_name={playerProfile.last_name} language={playerProfile.language}/>: <LandingPage/>} />
              <Route path="/join-club" element={isLoggedIn? <JoinClub/>: <LandingPage/>}/>
              <Route path="/profile" element={isLoggedIn? <ProfilePage playerProfileProp={playerProfile} />: <LandingPage/>}/>
              <Route path="/dashboard" element={(isLoggedIn && playerProfile)? <DashBoard first_name={playerProfile.first_name} last_name={playerProfile.last_name} language={playerProfile.language}/>: <LandingPage/>} />
              <Route path="/announcements" element={isLoggedIn? <AnnouncementPage/> : <LandingPage/>}/>
              <Route path="/leaderboard" element={isLoggedIn? <LeaderBoard/> : <LandingPage/>}/>
              <Route path="/attendance-tracker" element={isLoggedIn?<AttendanceTracker/>: <LandingPage/>}/>
              <Route path="/requests" element={isLoggedIn? <ManageClub/>: <LandingPage/>}/>
              <Route path="/manage-court" element={isLoggedIn? <ManageCourt/>: <LandingPage/>}/>
            </Routes>
          </Router>
        </GlobalProvider>
    )

}

export default App;
