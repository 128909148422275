import {FC} from "react";
import {Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr} from "@chakra-ui/react";
import {PlayerStat} from "../types";

type PlayerTableProps = {
  playerStats: PlayerStat[]
}

/**
 * Show player stats in a table.
 *
 * @param players
 */
const PlayerTable: FC<PlayerTableProps> = ({playerStats}) => {
  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Rank</Th>
            <Th>Name</Th>
            <Th>Elo Rating</Th>
            <Th>Event Name</Th>
          </Tr>
        </Thead>
        <Tbody>
          {playerStats.map((l, i) =>
            <Tr key={i}>
              <Td isNumeric>{l.rank}</Td>
              <Td>{l.name}</Td>
              <Td>{l.eloRating}</Td>
              <Td>{l.event_data}</Td>
            </Tr>
          )}
        </Tbody>
        <Tfoot>
        <Tr>
            <Th>Rank</Th>
            <Th>Name</Th>
            <Th>Elo Rating</Th>
            <Th>Event Name</Th>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  )
}

export default PlayerTable
