import { Box, Button, Stack } from "@chakra-ui/react";
import { JoinRequest } from "../interfaces";

interface RequestCardProps {
    request: JoinRequest;
    onApprove: (requestId: number) => void;
}

const RequestCard: React.FC<RequestCardProps> = ({ request, onApprove }) => {
    return (
        <Box
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            my={4}
            key={request.request_id}
            bg="blue.100" // Set the background color to pastel blue
            color="black" // Set the font color for contrast
        >
            <Stack spacing={2} p={4}>
                <Box fontWeight="semibold" letterSpacing="wide" fontSize="xs" textTransform="uppercase">
                    Player Name: {request.player_name}
                </Box>
                <Box>
                    Request Date: {request.request_date}
                </Box>
                <Box>
                    Status: {request.status}
                </Box>
                <Button mt={4} onClick={() => onApprove(request.request_id)}>
                    Approve
                </Button>
            </Stack>
        </Box>
    );
};

export default RequestCard;
