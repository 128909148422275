import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import {extendTheme, ChakraProvider} from "@chakra-ui/react";

const theme = {
  colors: {
    brand: {
      900: '#1a365d',
      800: '#153e75',
      700: '#2a69ac',
    }
  },
  sizes: {
    prose: '80%'
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ChakraProvider theme={extendTheme(theme)}>
    <App/>
    </ChakraProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
