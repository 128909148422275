import { useState, FC, ChangeEvent, useEffect } from "react";
import useSWR, { useSWRConfig }  from "swr";
import { HStack, Stack, Text, FormControl, FormLabel, Select, Box, Table, Thead, Tbody, Td, Tfoot, Th, Tr, Heading, Input } from "@chakra-ui/react";

import ClubNav from "../components/ClubNav";
import { PlayerStat } from "../types";
import { fetcher } from "../libs/backend";
import { useGlobal } from "../GlobalContext";
import { getDjangoCookies } from "../utils";
import EventTypeFilter from "../components/EventTypeFilter";

interface Event {
  event_id: number;
  event_name: string;
}

interface DateRange {
  start_date: string;
  end_date: string;
}

interface SeasonInterFace {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  min_count: number;
  group: number;
}

interface SelectedPlayers {
    player_id: number;
    name: string;
    rating: number;
}

interface Player {
    user: number;
    first_name: string;
    last_name: string;
    language: string;
  }
  
/**
 * Show leaderboard. (sorted by ELO rating)
 */
const ManageCourt: FC = () => {
  const cookies = getDjangoCookies()
  const { state: { current_group_id, current_season_id } } = useGlobal()
  const { mutate } = useSWRConfig();

  // Fetch ratings data from api    
  const [selectedEvent, setSelectedEvent] = useState<any>("");  // Set 'All' as the default selected event
  const [selectedMatchType, setSelectedMatchType] = useState<string | undefined>(undefined);
  const { data: allPlayersData, error: allPlayersError } = useSWR<Player[]>(`api/group/${current_group_id}/players/`, fetcher);

  const [numberOfCourts, setNumberOfCourts] = useState<Number>(0);
  const [playersList, setPlayersList] = useState<SelectedPlayers[]>([]);

  const { data, error, isLoading } = useSWR(`/api/leaderboard/${selectedEvent}?${selectedMatchType}/`, fetcher, { shouldRetryOnError: false, revalidateOnFocus: false })


  function handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
    if (e.target.value !== "") {
        setSelectedEvent(e.target.value);
        mutate(`/api/leaderboard/${selectedEvent}?${selectedMatchType}/`);
    }
  }
  return (
    <>
      <Stack direction={['column', 'row']} spacing={5}>
        <ClubNav />
        <Stack direction='column' width="100%" paddingLeft={5}>

              <Box flex={1} p={2}>
                <Heading>Manage Court </Heading>
                <HStack>
              
                    <Box flex={1} width={{ base: "100%", md: "75%" }}>
                        <Text fontWeight={'semibold'}>Select Event</Text>
                        <EventTypeFilter currentSelectedSeasonId={current_season_id} onSelectChange={handleSelectChange} hideAll={false} />
                    </Box>
                    <Box flex={1} width={{ base: "100%", md: "75%" }}>
                        <FormControl>
                            <FormLabel>Number of Courts</FormLabel>
                            <Input id="number_of_courts" type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => setNumberOfCourts(parseInt(e.target.value))} />
                        </FormControl>
                    </Box>
                    <Box flex={1} width={{ base: "100%", md: "75%" }}>
                        <Text fontWeight={'semibold'}>Select Players</Text>

                    </Box>
                </HStack>
              </Box>


        </Stack>
      </Stack>
    </>
  )
}
export default ManageCourt;