import Cookies from 'js-cookie';

/**
 * Returns cookies set by django if user is signed in.
 */
function getDjangoCookies(): { csrftoken: string, sessionid: string } {
  return {
    csrftoken: Cookies.get('csrftoken') ?? '',
    sessionid: Cookies.get('sessionid') ?? '',
  }
}

export {
  getDjangoCookies
}
