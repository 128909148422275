import React, { FC, useState, useEffect } from "react";
import {
  Flex,
  Stack,
  Heading,
  Text,
  Box,
  CircularProgress,
  CircularProgressLabel,
  Tooltip
} from "@chakra-ui/react";
import { InfoOutlineIcon } from '@chakra-ui/icons'
import ClubNav from "../components/ClubNav";
import MatchTable from "../components/MatchTable";
import WinRateGraph from "../components/WinRateGraph";
import SeasonFilter from "../components/SeasonFilter";
import { buildApiUrl } from "../libs/environment";
import axios from "axios";
import { useGlobal } from "../GlobalContext";
import useSWR from "swr";
import { fetcher } from "../libs/backend";

/**
 * Dashboard - show current player's latest matches.
 */
interface Props {
  first_name: string;
  last_name: string;
  language: any;
}

interface SeasonInterFace {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  min_count: number;
  group: number;
}

const DashBoard: FC<Props> = ({ first_name, last_name, language }) => {
  const [currentSeason, setCurrentSeason] = useState<SeasonInterFace | null>(null);
  const [joiningClub, setJoiningClub] = useState<any | undefined>([]);
  const [loadingClubList, setLoadingClubList] = useState<boolean>(false);
  const [winningPercentage, setWinningPercentage] = useState<string>('');
  const { dispatch } = useGlobal();
  const {
    state: { current_group_id, current_season_id },
  } = useGlobal();

  // Fetch participation count data from api
  const { data: participationData, error: participationError } = useSWR(
    `/api/group/${current_group_id}/participation_count/${currentSeason? currentSeason.id+'/': ''}`,
    fetcher,
    { shouldRetryOnError: false, revalidateOnFocus: false }
  );
  // Fetch seasons data from api
  const { data: seasonsData, error: seasonsError } = useSWR(
    participationData ? `/api/group/${current_group_id}/seasons/` : null,
    fetcher,
    { shouldRetryOnError: false, revalidateOnFocus: false }
  );

  useEffect(() => {
    const fetchClubList = async () => {
      try {
        const response = await axios(buildApiUrl("/api/groups/"));
        setJoiningClub(response.data);
      } catch (error) {
        setTimeout(function () {
          window.location.replace("/accounts/kakao/login/?process=login");
        }, 5000);
      } finally {
        setLoadingClubList(true);
      }
    };

    fetchClubList();
  }, []);

  useEffect(() => {

    const getCurrentSeason = async () => {
      const todayDate: string = new Date().toISOString().split('T')[0];

      // Find the element where today's date falls within the date range
      const foundElement = seasonsData.find((item:SeasonInterFace) => item.start_date <= todayDate && todayDate <= item.end_date && item.name !== 'All');

      setCurrentSeason(foundElement);

      dispatch({
        type: "set_season",
        season_id: foundElement.id,
      });
    };

    getCurrentSeason();
  },[seasonsData])

/*   const handleSelectChangeSeason = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSeason = seasonsData.find((item: SeasonInterFace) => item.id === parseInt(e.target.value));
    setCurrentSeason(selectedSeason);
  }; */

  const handleUpdateWinningPercentage = (matchData: any) => {
    let totalWin = 0;
    let totalGamePlayed = 0;
    matchData.forEach((item:any) => {
      if (item.match_type !== 'P') {
        totalGamePlayed++;

        if (item.result === 'Win') {
          totalWin++;
        }
      }
    })

    setWinningPercentage(Number((totalWin / totalGamePlayed) * 100).toFixed(1));
    

  }

  useEffect(() => {
    if (current_group_id == undefined && loadingClubList) {
      const queryParams = new URLSearchParams(window.location.search);
      const club_id_from_url = queryParams.get("clubId");
      if (!club_id_from_url) {
        const joinedClub = joiningClub.filter(
          (club: any) => club.is_active && club.has_joined
        );
        if (joinedClub.length > 0) {
          dispatch({
            type: "set_group",
            group_id: parseInt(joinedClub[0].group_id),
          });
        } else {
          window.location.replace("/join-club");
        }
      } else {
        dispatch({
          type: "set_group",
          group_id: parseInt(club_id_from_url),
        });
      }
    }
  }, [loadingClubList]);

  if (!loadingClubList) {
    return null;
  } else {
    if (currentSeason && seasonsData && seasonsData.length > 0 && participationData) {
      return (
        <>
          <Stack direction={["column", "column", "row", "row"]} spacing={[2, 4]}>
            <ClubNav />
            <Stack flex={1} p={[2, 4]}>
              <Heading as="h1">
                Welcome{" "}
                {language === "KOR" ? last_name + first_name : first_name + " " + last_name}
              </Heading>
              <Box>
                <Heading size="lg">Progress for {currentSeason.name} season</Heading>
{/*                 <Box flex={1} width={{ base: "100%", md: "25%" }}>
                  <Text fontWeight={'semibold'}>View other season</Text>
                   <SeasonFilter onSelectChangeSeason={handleSelectChangeSeason}/> 
                </Box> */}
                <Flex direction={["column", "column", "row", "row"]} alignItems="center">
                  <Box mr={[0, 0, 4, 4]}>
                    <Text>Participation Tracker</Text>
                    <Flex direction="column" alignItems="center">
                      <CircularProgress
                        value={(participationData.participation_count / currentSeason.min_count) * 100}
                        size="120px" // Update size to a single value
                        thickness={8}
                      >
                        <CircularProgressLabel>
                          {participationData.participation_count} / {currentSeason.min_count}
                        </CircularProgressLabel>
                      </CircularProgress>
                    </Flex>
                  </Box>
                  {!isNaN(parseFloat(winningPercentage)) && <Box ml={[0, 0, 4, 4]}>
                    <Text>Win Percentage <Tooltip label='Excludes matches marked as "Practice (P)"' fontSize='md'><InfoOutlineIcon /></Tooltip> : </Text>
                     <WinRateGraph winningPercentage={winningPercentage} /> 
                  </Box>}
                </Flex>
              </Box>
              <MatchTable updateParentState={handleUpdateWinningPercentage} currentSelectedSeasonObj={currentSeason} seasonsData={seasonsData} />
            </Stack>
          </Stack>
        </>
      );

    } else {
      return null;
    }
  }


};

export default DashBoard;
