import { useMemo } from "react";
import useSWR from "swr";

import { Text, Stack, Alert } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

import { fetcher } from "../libs/backend";
import { getDjangoCookies } from "../utils";
import { useGlobal } from "../GlobalContext";

import { Announcement } from "../types";

import ClubNav from "../components/ClubNav";
import BoardTable from "../components/BoardTable";

const AnnouncementPage = () => {
  const cookies = getDjangoCookies();
  const { state: { current_group_id } } = useGlobal();

  // Fetch announcements data from API
  const { data, error } = useSWR(`/api/group/${current_group_id}/announcements/`, fetcher);

  console.log(data);
  console.log(data?.length);  // Check the length property of the data object

  // Parse announcements if backend returned data
  const announcements: Announcement[] = useMemo<Announcement[]>(
    () => {
      // On error return empty list, or if returned data is in bad shape
      if (!Array.isArray(data)){
        console.log('data is not array')
      }

      if (!data){
        console.log('data is not here')
      }

      if (error){
        console.log('there is error?')
      }

      if (!cookies.sessionid){
        console.log('cookie session gone')
      }

      if (error || !data || !Array.isArray(data)) {
        console.log('something is wrong')
        return [];
      }
  
      // Parse API response to fit Announcement type
      return data.map((item: any) => {
        return {
          id: item.id,
          date: new Date(item.date),
          creator_name: item.creator_name,
          group: item.group,
          title: item.title,
          message: item.message,
        };
      });
    },
    [data, error]
  );
  
  
  console.log(announcements)
  // console.log(announcements.length)

  return (
    <Stack direction="row">
      <ClubNav />
      {error ? (
        <Alert status="error">
          <InfoIcon />
          Failed to load announcements.
        </Alert>
      ) : (
        <Stack flex={1} p={2}>
          {announcements.length > 0 ? (
            <BoardTable announcements={announcements} />
          ) : (
            <Alert status="info" alignSelf="flex-start">
              <InfoIcon />
              There are no announcements.
            </Alert>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default AnnouncementPage;
