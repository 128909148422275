import {FC, useState} from "react";
import {Box, Select, Stack, Text} from "@chakra-ui/react";

import SeasonFilter from "./SeasonFilter";
import EventTypeFilter from "./EventTypeFilter";
import MatchTypeFilter from "./MatchTypeFilter";

/**
 * Filter UI for selecting matches.
 *
 * @constructor
 */

interface DateRange {
  start_date: string;
  end_date: string;
}

interface MatchTableProps {
  currentSelectedSeasonId: number,
  onSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  onSelectChangeMatch: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  onChangeStartDate: (e: any) => void,
  onChangeEndDate: (e: any) => void,
  dateRange: DateRange
}

const MatchFilter: FC<MatchTableProps> = ({currentSelectedSeasonId, onSelectChange, onSelectChangeMatch, onChangeStartDate, onChangeEndDate, dateRange}) => {
    
  //
  // useEffect(() => {
  //
  //   const loadEventType = async () => {
  //     const response = await axios(buildApiUrl(`/api/groups/${current_club_id}/events/`));
  //     // setEventTypeList(response.data);
  //     setIsEventTypeLoading(false);
  //   };
  //   loadEventType();
  // }, []);


  return (
    <Stack direction={'row'} mb={4}>
      <Box flex={1}>
        <Text fontWeight={'semibold'}>Match Type</Text>
        <MatchTypeFilter fromDashBoard={true} onSelectChangeMatch={onSelectChangeMatch}/>
      </Box>
      <Box flex={1}>
        <Text fontWeight={'semibold'}>Event Type</Text>
        <EventTypeFilter currentSelectedSeasonId={currentSelectedSeasonId} onSelectChange={onSelectChange} hideAll={false} />
      </Box>
      <Box flex={1}>
      <Text fontWeight={'semibold'}>Date Range</Text>
          <Stack direction='row' alignItems='center' spacing={2}>
            <Text>Start Date:</Text>
            <input type='date' value={dateRange.start_date} onChange={onChangeStartDate} />
            <Text>End Date:</Text>
            <input type='date' value={dateRange.end_date} onChange={onChangeEndDate} />
          </Stack>
      </Box>
    </Stack>
  )
}

export default MatchFilter;
